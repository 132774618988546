<!-- eslint-disable no-undef -->
<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Administrar Recordatorios
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="getNextapoitments()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click.prevent="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content :key="reloadPatientsV">
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>

          <v-tab href="#tab-1">
            GESTIÓN DE NOTIFICACIONES
            <v-icon>mdi-bell-ring</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat class="py-1 px-1">
              <block v-if="loading"></block>
              <v-card-text class="white py-4">
                <v-row class="mx-2">
                  <v-col class="tabsNh" cols="12">
                    <v-col
                      cols="12"
                      style="display: flex; justify-content: flex-end"
                    >
                      <v-btn
                        small
                        class="btnclean mr-2"
                        @click="cleanFilters()"
                      >
                        <v-icon>mdi-filter-remove</v-icon>
                      </v-btn>
                      <v-btn small class="btnclean mr-2" @click="dialog = true">
                        <v-icon>mdi-filter</v-icon>
                      </v-btn>
                      <v-btn @click="sendReminder()" small class="btnsave">
                        <v-icon>mdi-email</v-icon>
                      </v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="10" sm="3" md="3" lg="3" xl="3"  class="mt-3">
                    <v-autocomplete
                      outlined
                      dense
                      :items="TM"
                      item-text="Name"
                      item-value="Id"
                      label="Plantilla de correo"
                      v-model="selectedTemplate"
                      attach
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <GeneralFilter
                      ref="generalFilter"
                      :search="search"
                      :filterEndpoint="filterPromise"
                      @filtered="filterHandler"
                      @emptyFilter="clearFilter"
                    >
                      <DxDataGrid class="isScrolledByGeneralFilter"
                        :data-source="
                          !isFiltered ? apointmentList : dataFiltered
                        "
                        key-expr="ID_Convert"
                        :show-row-lines="showRowLines"
                        :show-borders="showBorders"
                        :row-alternation-enabled="rowAlternationEnabled"
                        @selection-changed="onSelectionChanged($event)"
                        @exporting="onExporting"
                      >
                        <DxPaging :page-size="10" />
                        <DxScrolling row-rendering-mode="virtual" />
                        <DxExport :enabled="true" />
                        <DxSelection mode="multiple" />
                        <DxColumn
                          data-field="ID_Patient"
                          caption="ID de Paciente"
                        ></DxColumn>
                        <DxColumn
                          data-field="NamePatient"
                          caption="Nombre de Paciente"
                        ></DxColumn>
                        <DxColumn
                          data-field="ID_Customer"
                          caption="Nombre cliente"
                        ></DxColumn>

                        <DxColumn data-field="Breed" caption="Raza"></DxColumn>
                        <DxColumn
                          data-field="NotificationEmail"
                          caption="Correo destinatario"
                        ></DxColumn>
                        <DxColumn
                          data-field="NotificationType"
                          caption="Tipo de notificacion"
                        ></DxColumn>
                        <DxColumn
                          data-field="NotificationDate"
                          caption="Fecha de notificacion"
                        ></DxColumn>
                        <template #show-template="{ data }">
                          <a
                            href="#"
                            class="mx-1"
                            @click="patientSelected(data)"
                          >
                            <v-icon color="primary">mdi-eye</v-icon>
                          </a>
                        </template>
                      </DxDataGrid>
                    </GeneralFilter>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-dialog v-model="dialog" width="60%">
                <v-card>
                  <v-card-title class="d-flex justify-space-between">
                    <span class="headline">Filtros</span>
                    <div
                      style="
                        margin-top: 0.3rem;
                        margin-bottom: 0.3rem;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <v-btn
                        small
                        class="btndelete"
                        @click.prevent="showNotes = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-col cols="12" class="mt-2">
                      <v-row>
                        <v-col cols="3">
                          <v-autocomplete
                            outlined
                            dense
                            attach
                            :items="speciesList"
                            v-model="speciesSelected"
                            label="Especie"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="3">
                          <v-autocomplete
                            outlined
                            dense
                            v-if="
                              speciesSelected == 'Canino' ||
                              speciesSelected == 'Felino'
                            "
                            :items="breedList"
                            attach
                            v-model="breedSelected"
                            label="Raza"
                          ></v-autocomplete>
                          <v-text-field
                            outlined
                            dense
                            v-else
                            v-model="breedSelected"
                            label="Raza"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <date-picker
                            :value="startDate"
                            @dateSelected="
                              (param) => {
                                startDate = param;
                              }
                            "
                            label="Fecha de inicio"
                          ></date-picker>
                        </v-col>
                        <v-col cols="6">
                          <date-picker
                            :value="endDate"
                            @dateSelected="
                              (param) => {
                                endDate = param;
                              }
                            "
                            label="Fecha de fin"
                          ></date-picker>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="btndelete" @click="dialog = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      class="btn-add"
                      @click="
                        dialog = false;
                        getNextapoitments();
                      "
                      >Aplicar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <alerts
          v-if="alert.show"
          v-on:close_alert="closeAlert"
          v-on:accept_alert="acceptAlert"
          :properties="alert"
        ></alerts>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
  DxSelection,
  DxExport,
} from "devextreme-vue/data-grid";
import { mapGetters, mapState } from "vuex";
import DatePicker from "../../components/DatePicker.vue";
import { DogSpecies, CatSpecies } from "../../helpers/species";
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";
import { printToExcel } from "@/helpers/printToexcel";
import { multipleCriteriaFilter } from "@/helpers/object";

export default {
  name: "VeterinaryPatientReminders",
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxPaging,
    DatePicker,
    DxSelection,
    Block,
    Alerts,
    DxExport,
    GeneralFilter,
  },
  props: ["win"],
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      loading: false,
      speciesList: ["Canino", "Felino", "Roedor", "Reptil", "Ave", "Otro"],
      search: {},
      dialog: false,
      dialogDelete: false,
      tab: null,
      veterinarypatients: [],
      showPatient: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      reloadPatientsV: 0,
      showOnlyDogs: false,
      showOnlyCats: false,
      showOnlyOthers: false,
      currentWeek: 0,
      selctedPatients: [],
      startDate: null,
      dataMail: [],
      endDate: null,
      TM: [[]],
      selectedTemplate: null,
      speciesSelected: null,
      breedSelected: null,
      dataAppointment: {},
      isFiltered: false,
      dataFiltered: [],
      didHaveAllData: false,
    };
  },
  computed: {
    ...mapGetters(["reloadData"]),
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    apointmentList() {
      let template = this.TM.find((item) => {
        return item.Id == this.selectedTemplate;
      });
      if (template)
        return this.veterinarypatients.filter((item) => {
          return item.typeN == template.Type;
        });
      else return this.veterinarypatients;
    },
    breedList() {
      if (this.speciesSelected == "Canino") return DogSpecies;
      else if (this.speciesSelected == "Felino") return CatSpecies;
      else return [];
    },
  },
  watch: {
    reloadData: {
      immediate: true,
      handler: "reloadPatients",
    },
  },
  mounted() {
    this.search.business =
      JSON.parse(localStorage.getItem("user")).businessid || "";
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.getCurrentWeek();
    this.getTemplateList();
    this.getNextapoitments();
  },
  methods: {
    onExporting(e) {
      printToExcel(e, "Recordatorios");
    },
    cleanFilters() {
      this.speciesSelected = null;
      this.breedSelected = null;
      this.startDate = null;
      this.endDate = null;
      this.getCurrentWeek();
      this.getNextapoitments();
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getNextapoitments() {
      this.loading = true;
      let data = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      this.dataAppointment = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      this.search.dataAppointment = this.dataAppointment;
      this.$API.veterinarypatients
        .findnextApoitmentsHistory(data || this.dataAppointment)
        .then((response) => {
          response.map((item) => {
            if (item.patient) {
              item.Breed = item.patient.Breed;
              item.NamePatient = item.patient.NamePatient;
              item.ID_Customer = item.patient.ID_Customer;
              item.ID_Patient = item.patient.ID_Patient;
              item.NotificationEmail = item.patient.NotificationEmail;
            }
            switch (item.typeN) {
              case "Grooming":
                item.NotificationType = "Peluquería";
                item.NotificationDate = item.NextAppointment;
                item.ID_Convert = item.ID_VPG + "Grooming";
                break;
              case "Medications":
                item.NotificationType =
                  item.ItemType == "VAC" ? "Vacunas" : "Medicamentos";
                item.NotificationDate = item.NextTime;
                item.ID_Convert = item.ID_VPMP + "Medications";
                break;
              case "Surgery":
                item.NotificationType = "Cirugía";
                item.NotificationDate = item.SurgeryDate;
                item.ID_Convert = item.ID_Surgery + "Surgery";
                break;
              case "Prescription":
                item.NotificationType = "Prescripción";
                item.NotificationDate = item.NextAppointment;
                item.ID_Convert = item.ID_VPMP + "Prescription";
                break;
              default:
                item.NotificationType = "Otro";

                break;
            }
            return {
              ...item,
            };
          });

          response = this.filterData(response);

          this.veterinarypatients = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getNextapoitmentsAllList() {
      this.loading = true;
      let data = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      this.dataAppointment = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      this.search.dataAppointment = this.dataAppointment;
      this.$API.veterinarypatients
        .findnextApoitments(data || this.dataAppointment)
        .then((response) => {
          response.map((item) => {
            if (item.patient) {
              item.Breed = item.patient.Breed;
              item.NamePatient = item.patient.NamePatient;
              item.ID_Customer = item.patient.ID_Customer;
              item.ID_Patient = item.patient.ID_Patient;
              item.NotificationEmail = item.patient.NotificationEmail;
            }
            switch (item.typeN) {
              case "Grooming":
                item.NotificationType = "Peluquería";
                item.NotificationDate = item.NextAppointment;
                item.ID_Convert = item.ID_VPG + "Grooming";
                break;
              case "Medications":
                item.NotificationType =
                  item.ItemType == "VAC" ? "Vacunas" : "Medicamentos";
                item.NotificationDate = item.NextTime;
                item.ID_Convert = item.ID_VPMP + "Medications";
                break;
              case "Surgery":
                item.NotificationType = "Cirugía";
                item.NotificationDate = item.SurgeryDate;
                item.ID_Convert = item.ID_Surgery + "Surgery";
                break;
              case "Prescription":
                item.NotificationType = "Prescripción";
                item.NotificationDate = item.NextAppointment;
                item.ID_Convert = item.ID_VPMP + "Prescription";
                break;
              default:
                item.NotificationType = "Otro";

                break;
            }
            return {
              ...item,
            };
          });

          response = this.filterData(response);

          this.veterinarypatients = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    filterData(data) {
      data = data.filter((item) => {
        if (this.speciesSelected) {
          if (item.patient.Species != this.speciesSelected) return false;
        }
        if (this.breedSelected) {
          if (item.patient.Breed != this.breedSelected) return false;
        }
        return true;
      });
      return data;
    },
    onSelectionChanged(event) {
      let selectedRowsData = event.selectedRowsData;
      var isDeselect = false;
      if (event.currentDeselectedRowKeys.length > 0) {
        isDeselect = true;
        Object.assign(
          {},
          ...event.currentDeselectedRowKeys.map((x) => {
            this.dataMail.splice(
              this.dataMail.findIndex(
                (item) => item.ID_Convert === x.ID_Convert
              ),
              1
            );
          })
        );
      } else {
        isDeselect = false;
      }
      if (isDeselect == false) {
        this.dataMail = [];
        var parsedobj = JSON.parse(JSON.stringify(selectedRowsData));
        Object.assign(
          {},
          ...parsedobj.map((x) => {
            this.dataMail.push(x);
          })
        );
      }
    },
    getCurrentWeek() {
      const currentDate = new Date();
      const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
      const pastDaysOfYear = (currentDate - firstDayOfYear) / 86400000;
      this.currentWeek = Math.ceil(
        (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
      );

      const startDate = new Date(
        currentDate.getFullYear(),
        0,
        1 + (this.currentWeek - 1) * 7
      );
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 6
      );

      this.startDate = this.moment(startDate).format("DD/MM/YYYY");
      this.endDate = this.moment(endDate).format("DD/MM/YYYY");
    },
    sendReminder() {
      this.loading = true;
      let template = this.TM.find((item) => {
        return item.Id == this.selectedTemplate;
      });
      let data = {
        mailBoxData: this.dataMail,
        templateData: template,
      };
      this.$API.notification
        .sendVeterinaryReminder(data) 
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Notificacion enviada",
            "Se ha enviado la notificacion correctamente"
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al enviar la notificacion"
          );
        });
    },
    getTemplateList() {
      this.$API.TM.getTM().then((response) => {
        response = response.filter((item) => {
          return (
            item.State == 1 &&
            (item.Type == "Grooming" ||
              item.Type == "Medications" ||
              item.Type == "Surgery" ||
              item.Type == "Prescription")
          );
        });
        this.selectedTemplate = response[0].Id;
        this.TM = response;
      });
    },
    async reloadPatients() {
      if (this.reloadData == "veterinarypatients") {
        await this.getAllPatients();
        this.$store.dispatch("reloadData", null);
      }
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
        //alert(this.$store.getters.getScrollTop)
      }, 300);
    },
    patientSelected(data) {
      // this.$store.dispatch('deleteWindow', {name: 'patientData'});

      this.$API.customers
        .getOneCustomer(data.data.ID_Customer)
        .then((response) => {
          data.data.customer = response;

          this.$store.dispatch("addWindow", {
            name: "veterinaryPatientData",
            component: "VeterinaryPatientData",
            unique: false,
            meta: { veterinarypatients: data.data, reload: true },
          });
        });
    },
    filterPromise(filterObj) {
      const filterArray = [
        "ID_Patient",
        "NamePatient",
        "ID_Customer",
        "Breed",
        "NotificationEmail",
        "NotificationType",
        "NotificationDate",
      ];
      return new Promise((resolve, reject) => {
        try {
          resolve(
            multipleCriteriaFilter(filterArray, filterObj, this.apointmentList)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    clearFilter() {
      this.isFiltered = false;
    },
    filterHandler(response) {
      if (response.length === 0 && this.didHaveAllData === false) {
        this.didHaveAllData = true;
        this.getNextapoitmentsAllList();
        this.$refs.generalFilter.filterHandler();
      }

      this.isFiltered = true;
      this.dataFiltered = response;
    },
  },
};
</script>

<style scoped>
.v-input__control {
  background: white !important;
}
.tabsNh {
  width: 100%;
  padding: 10px;

  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
</style>
